import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarth } from '@fortawesome/pro-solid-svg-icons'
import { Popover } from '@web/shared-ui-components'
import { ReactElement, useState } from 'react'

export interface StoneOriginIconProps {
  countryOfOrigin?: string | null
  geographicalOrigin?: string | null
  isKnownOrigin?: boolean | null
}

function StoneOriginIcon ({ countryOfOrigin, geographicalOrigin, isKnownOrigin }: StoneOriginIconProps): ReactElement | null {
  if (countryOfOrigin == null && geographicalOrigin == null) {
    return null
  }

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const [refElement, setRefElement] = useState<HTMLDivElement | null>(null)

  let origin = countryOfOrigin
  if (geographicalOrigin != null) {
    if (origin == null) {
      origin = geographicalOrigin
    } else {
      origin = `${geographicalOrigin}, ${origin}`
    }
  }

  return (
    <div className=' order-5 position-static productFeaturedAttributes' data-test='product-featured-attributes'>
      <div className='featured-attribute-icon-height w-100 d-flex mt-1 mx-0 position-relative' style={tooltipVisible ? { zIndex: 6 } : {}}>
        <div className='h-100 featured-attribute-icon-width mr-1 position-relative'>
          <div className='h-100 d-inline-block' ref={setRefElement}>
            <FontAwesomeIcon icon={faEarth} size='lg' className='stoneOriginIcon' />
          </div>

          <Popover
            boxShadow
            textWrap
            placement='top'
            hover
            offset={5}
            hoverProps={{ delay: { open: 300 } }}
            open={tooltipVisible}
            onOpenChange={setTooltipVisible}
            reference={refElement}
          >
            <b style={{ whiteSpace: 'nowrap' }}>{origin}</b>
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>{isKnownOrigin === true ? '(known origin)' : '(origin assumed)'}</span>
          </Popover>

        </div>
      </div>
    </div>
  )
}

export { StoneOriginIcon }
