import { GetSearchNavigationQuery, SearchPresentationContext } from '@web/shared-data-access-queries'
import { createContext } from 'react'

const ProductNavigationContext = createContext<GetSearchNavigationQuery['getSearchNavigation']>(
  {
    __typename: 'CategoryOrSearchViewModel',
    PresentationContext: SearchPresentationContext.Search,
    RedirectUrl: null,
    BottomNavigationSectionContentId: null,
    UnderCategorySectionContentId: null,
    ShowCenterStoneColors: false,
    ClearAllUrl: null,
    HideSideNav: false,
    PageHeader: 'Default Header',
    PageTitle: 'Default Title',
    ArticlesCount: 0,
    ShowDiscounts: false,
    SearchSource: null,
    ShowSeriesAboveImage: false,
    Query: null,
    QueryId: null,
    Category: null,
    CategoryId: null,
    CategoryPermalinkUrl: null,
    TopAcrossPageContentId: null,
    ContentId: null,
    AllowNewContentBlockCreation: false,
    NewContentBlockName: 'Default NewContentBlockName',
    ContentBlockLocation: 0,
    CmsContentType: 0,
    IsContentOnlyDisplayTemplate: false,
    OriginalQuery: null,
    CorrectedQuery: null,
    TopRowInResultsContentID: null,
    ImpressionListName: null,
    ShowPageSize: false,
    MerchandisingMode: false,
    BreadCrumbs: null,
    CenterStoneColors: null,
    ChildCategories: null,
    FacetValues: null,
    NavigationSections: null,
    TopNavigationSections: null,
    SelectedFacets: null,
    SearchResults: null,
    RelatedSearchTerms: null,
    Pager: null,
    Filters: null,
    SortByFilter: [],
    PageSizeFilter: [],
    StoneForViewAvailableMountings: null,
    SearchByStoneSize: null
  }
)

export { ProductNavigationContext }
