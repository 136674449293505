import { ReactElement } from 'react'
import { FancySelect, FancySelectOption, ContactInformationFormInput } from '@web/shared-ui-components'
import { ContactViewModel } from '@web/shared-data-access-queries'
import { ActionMeta } from 'react-select'

function contactToOption (contact: ContactViewModel): FancySelectOption {
  return {
    label: `${contact.FirstName} ${contact.LastName}`,
    value: contact.Id?.toString() ?? ''
  }
}

interface ContactInformationDropdownProps {
  contacts?: ContactViewModel[]
  formInput?: ContactInformationFormInput
  onSelect?: (contact: ContactViewModel | undefined) => void
  selectedContact?: ContactViewModel
}

function ContactInformationDropdown ({ contacts, formInput, onSelect, selectedContact }: ContactInformationDropdownProps): ReactElement {
  const contact: ContactViewModel | undefined = selectedContact ?? contacts?.find(contact => contact.Id?.toString() === formInput?.ContactId?.toString())
  const selectedOption: FancySelectOption | undefined = contact != null ? contactToOption(contact) : undefined
  const options: FancySelectOption[] = contacts?.map(contact => contactToOption(contact)) ?? []

  const handleSelect = (newValue: FancySelectOption | undefined, actionMeta: ActionMeta<FancySelectOption>): void => {
    if (newValue != null && actionMeta.action === 'select-option') {
      onSelect?.(contacts?.find(x => x.Id?.toString() === newValue?.value))
    }
  }

  return (
    <div>
      <label className='font-weight-bold d-block'>Contact</label>
      <FancySelect
        options={options}
        value={selectedOption ?? options[0]}
        isSearchable={false}
        color='secondary'
        onChange={handleSelect}
      />
    </div>
  )
}

export { ContactInformationDropdown }
