import { ContactViewModel } from '@web/shared-data-access-queries'
import { ReactElement } from 'react'
import { ContactInformationDropdown } from './ContactInformationDropdown'

interface ContactInformationProps {
  /**
   * List of contacts
   */
  contacts?: ContactViewModel[]
  /**
   * Handler to change the selected contact and the contact id on the request
   */
  onHandleContactChange: (contact: ContactViewModel | undefined) => void
  /**
   * Selected contact
   */
  selectedContact: ContactViewModel
}

function ContactInformation ({
  contacts,
  onHandleContactChange,
  selectedContact
}: ContactInformationProps): ReactElement {
  const formatContactName = (contact: ContactViewModel): string => {
    const formattedSuffix = contact.Suffix !== null ? ` ${contact.Suffix}` : ''
    return `${contact.FirstName} ${contact.LastName}${formattedSuffix}`
  }

  return (
    <>
      <div className='u-flex-grid-row my-3'>
        <div className='u-flex-grid-col-12 u-flex-grid-col-md-4'>
          {contacts != null && contacts.length > 1
            ? (
              <ContactInformationDropdown
                contacts={contacts}
                selectedContact={selectedContact}
                onSelect={onHandleContactChange}
              />
              )
            : (
              <>
                <label>Name</label>
                <div>{formatContactName(selectedContact)}</div>
              </>)}
        </div>
      </div>
      <div className='u-flex-grid-row'>
        <div className='u-flex-grid-col-12 u-flex-grid-col-md-3 mb-3'>
          <label>Business Phone</label>
          <div>{selectedContact.BusinessPhoneNumber}</div>
        </div>
        <div className='u-flex-grid-col-12 u-flex-grid-col-md-3 mb-3'>
          <label>Mobile Phone</label>
          <div>{selectedContact.MobilePhoneNumber}</div>
        </div>
        <div className='u-flex-grid-col-12 u-flex-grid-col-md-3 mb-3'>
          <label>Email Address</label>
          <div>{selectedContact.EmailAddress}</div>
        </div>
      </div>
    </>
  )
}

export { ContactInformation }
