import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Button, FancySelect, FancySelectOption } from '@web/shared-ui-components'
import { faSearch, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAddAHeadQuery } from '@web/products'

interface SearchBySeriesNumberProps {
  selectedSeries: string | null
  atoModelNumber: string
  iterationId: string | null
  excludedIterationIds: string[] | null
  shouldFilterOnIteration?: boolean
  useIterationsSystemSetting?: boolean
  onSeriesNumberSearch: (seriesNumber: string) => void
}

function SearchBySeriesNumber ({ selectedSeries, atoModelNumber, iterationId, excludedIterationIds, shouldFilterOnIteration, useIterationsSystemSetting, onSeriesNumberSearch }: SearchBySeriesNumberProps): ReactElement | null {
  const [inputValue, setInputValue] = useState<string>(selectedSeries ?? '')

  useEffect(() => {
    setInputValue(selectedSeries ?? '')
  }, [selectedSeries])

  // @todo need to say when to use iterationId here
  const { data, loading } = useAddAHeadQuery({
    atoModelNumber,
    page: 0,
    pageSize: 0,
    iterationId,
    useIterationsSystemSetting,
    shouldFilterOnIteration,
    excludedIterationIds,
    series: inputValue,
    autocomplete: true
  })

  const seriesNumberOptions: FancySelectOption[] = useMemo(() => {
    const seriesOptions: string[] | undefined | null = data?.getAddAHeadFacetsAndResults?.Facets?.series

    if (inputValue.trim().length > 0 && seriesOptions != null) {
      return seriesOptions.map((seriesNumber: string) => {
        return {
          value: seriesNumber,
          label: <span>{seriesNumber}</span>
        }
      })
    }

    return []
  }, [data])
  const noOptionsValue = useMemo<string | ReactElement>(() => {
    if (loading) {
      return <FontAwesomeIcon icon={faSpinnerThird} size='lg' spin />
    }

    if (inputValue.trim().length > 0 && seriesNumberOptions.length <= 0) {
      return `No series numbers found starting with '${inputValue}'`
    }

    return 'Start typing a series number'
  }, [inputValue, seriesNumberOptions, loading])

  function handleSeriesNumberSelection (newValue, actionMeta): void {
    if (newValue !== null && actionMeta.action === 'select-option') {
      onSeriesNumberSearch(newValue.value)
    }
  }

  function handleInputValueChange (newValue: string, actionMeta): void {
    // React-Select by default will clear the input value when a user selects an option or removes focus from the input
    // This will prevent that behavior
    if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
      setInputValue(newValue)
    }
  }

  function handleSubmit (seriesNumber: string, event?): void {
    if (event != null) {
      event.preventDefault()
    }

    if (seriesNumber.length > 0) {
      onSeriesNumberSearch(seriesNumber)
      setInputValue('')
    }
  }

  return (
    <form autoComplete='off' onSubmit={event => handleSubmit(inputValue, event)}>
      <div className='form-input-group'>
        <div className='d-flex' data-test='search-by-series-container'>
          <FancySelect
            className='w-100'
            id='seriesNumber'
            inputId='search-by-series-number'
            placeholder='Series #'
            noOptionsMessage={() => noOptionsValue}
            onChange={(newValue, actionMeta) => handleSeriesNumberSelection(newValue, actionMeta)}
            onInputChange={(newValue, actionMeta) => handleInputValueChange(newValue, actionMeta)}
            inputValue={inputValue}
            defaultInputValue={inputValue}
            value={inputValue.length > 0 ? { value: inputValue, label: <span>{inputValue}</span> } : null}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            options={seriesNumberOptions}
            filterOption={null}
            isSearchable
            styles={({
              control: styles => ({
                ...styles,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: 'none'
              }),
              menuPortal: styles => ({
                ...styles, zIndex: 9999
              })
            })}
          />
          <Button
            className='u-border-radius-left-0 p-0'
            type='submit'
            color='primary'
            style={{
              minWidth: '40px'
            }}
            data-test='search-by-series-submit-button'
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </div>
      </div>
    </form>
  )
}

export { SearchBySeriesNumber }
