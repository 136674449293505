import React, { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faRecycle } from '@fortawesome/pro-solid-svg-icons'
import { Popover } from '@web/shared-ui-components'

export interface RecycledContentIconProps {
  isSCSCertified?: boolean
  isMadeWithCycledMaterials?: boolean
  isPrincipledSourcing?: boolean
}
interface LearnMore {
  url: string
  message: string
}

const RecycledContentIcon = React.memo(({ isSCSCertified, isMadeWithCycledMaterials, isPrincipledSourcing }: RecycledContentIconProps): ReactElement | null => {
  if (!(isSCSCertified ?? false) && !(isMadeWithCycledMaterials ?? false) && !(isPrincipledSourcing ?? false)) {
    return null
  }
  const [showRecycledIconTooltip, setShowRecycledIconTooltip] = useState<boolean>(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const scsCertifiedIconUrl = 'https://assets.stullercloud.com/das/84852028.svg'

  const learnMoreTooltip = (): ReactElement => {
    const learnMore: LearnMore = { url: 'https://www.stuller.com/corporate-responsibility', message: '' }

    if (isSCSCertified ?? false) {
      learnMore.url += '#crMetals'
      learnMore.message = "Stuller's gold manufactured metals are certified 100% recycled materials by SCS Global Services. "
    }
    if (isMadeWithCycledMaterials ?? false) {
      learnMore.url += '#crMetals'
      learnMore.message = 'One or more variations of this product are made with recycled materials. '
    }
    if (isPrincipledSourcing ?? false) {
      learnMore.url += '#crPackaging'
      learnMore.message = 'One or more variations of this product are made with materials from responsible sources. '
    }

    return (
      <div>
        {learnMore.message}
        <a target='_blank' rel='noreferrer' className='primary-link text-nowrap' href={learnMore.url}>
          Learn More
        </a>
      </div>
    )
  }

  const MemoizedPopover = React.memo(Popover)

  return (
    <div className=' order-5 position-static productFeaturedAttributes' data-test='product-featured-attributes'>
      <div className='featured-attribute-icon-height w-100 d-flex mt-1 mx-0 position-relative' style={showRecycledIconTooltip ? { zIndex: 6 } : {}}>
        <div
          className='h-100 featured-attribute-icon-width mr-1 position-relative'
        >
          <div
            className='h-100 d-inline-block'
            ref={setReferenceElement}
          >
            {(isSCSCertified ?? false) && <img src={scsCertifiedIconUrl} className='h-100 w-100' />}
            {(isPrincipledSourcing ?? false) && <FontAwesomeIcon icon={faLeaf} size='lg' className='recycledContentIcon' />}
            {(isMadeWithCycledMaterials ?? false) && <FontAwesomeIcon icon={faRecycle} size='lg' className='recycledContentIcon' />}
          </div>

          <MemoizedPopover
            boxShadow
            textWrap
            placement='top'
            hover
            offset={5}
            style={{ width: '260px' }}
            hoverProps={{ delay: { open: 300 } }}
            open={showRecycledIconTooltip}
            onOpenChange={setShowRecycledIconTooltip}
            reference={referenceElement}
          >
            {learnMoreTooltip()}
          </MemoizedPopover>

        </div>
      </div>
    </div>
  )
})

export { RecycledContentIcon }
